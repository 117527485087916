import {gql} from "@apollo/client";

export const getAllProducts = gql`
query getAllProducts {
    products(publicationState: PREVIEW, pagination: {limit:-1}) {
        data {
            id
            attributes {
                name
                slug
                wine {
                    information {
                        mainAppellation
                        wineAppellation
                        grapeVariety
                        alcohol
                        certification
                        bio
                    }
                    certification {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                    vinification {
                        method
                    }
                    packaging {
                        format
                        packaging
                    }
                    medal {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                    terroir {
                        wineRegion
                        surface
                        soilType
                        climate
                    }
                    tasting {
                        eye
                        nose
                        mouth
                    }
                    wineService {
                        tastingTemperature
                        foodWinePairing
                        wineConservation
                    }
                }
              
              beer{
                  beerInformation{
                    alcohol
                    country
                    bio
                  }
                  beerPackaging{
                    id
                    volumeBouteille
                    volumeFut
                    formatFut
                    teteFut
                    consigneBouteille
                    conditionnementBouteille
                    format
                  }
                  beerCertification {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                  beerMedal {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                  beerTasting {
                        eye
                        nose
                        mouth
                    }
                  beerService {
                        tastingTemperature
                        foodBeerPairing
                        beerConservation
                    }
                }
                image {
                    data {
                        id
                        attributes {
                            url
                            alternativeText
                        }
                    }
                }
                company {
                    data {
                        id
                        attributes {
                            name
                            slug
                        }
                    }
                }
                productSubCategory {
                    data {
                        id
                        attributes {
                            name
                            product_categories {
                                data {
                                    id
                                    attributes {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
                product_sub_3_cat {
                    data {
                        id
                        attributes {
                            name
                        }
                    }
                }
                product_sub_4_cat {
                    data {
                        id
                        attributes {
                            name
                        }
                    }
                }
            }
        }
    }
}
`

export const getProductById = gql`
query getProductById($idval: ID! = 1) {
    product(id: $idval) {
        data {
            id
            attributes {
                name
                slug
                wine {
                    information {
                        mainAppellation
                        wineAppellation
                        grapeVariety
                        alcohol
                        certification
                        bio
                    }
                    certification {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                    vinification {
                        method
                    }
                    packaging {
                        format
                        packaging
                    }
                    medal {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                    terroir {
                        wineRegion
                        surface
                        soilType
                        climate
                    }
                    tasting {
                        eye
                        nose
                        mouth
                    }
                    wineService {
                        tastingTemperature
                        foodWinePairing
                        wineConservation
                    }
                }
              beer{
                  beerInformation{
                    alcohol
                    country
                    bio
                  }
                  beerPackaging{
                    id
                    volumeBouteille
                    volumeFut
                    formatFut
                    teteFut
                    consigneBouteille
                    conditionnementBouteille
                    format
                  }
                  beerCertification {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                  beerMedal {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                  beerTasting {
                        eye
                        nose
                        mouth
                    }
                  beerService {
                        tastingTemperature
                        foodBeerPairing
                        beerConservation
                    }
                }
                image {
                    data {
                        id
                        attributes {
                            url
                            alternativeText
                        }
                    }
                }
                company {
                    data {
                        id
                        attributes {
                            name
                            slug
                        }
                    }
                }
                productSubCategory {
                    data {
                        id
                        attributes {
                            name
                            product_categories {
                                data {
                                    id
                                    attributes {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
                product_sub_3_cat {
                    data {
                        id
                        attributes {
                            name
                        }
                    }
                }
                product_sub_4_cat {
                    data {
                        id
                        attributes {
                            name
                        }
                    }
                }
            }
        }
    }
}
`
export const getProductBySlug = gql`
query getProductBySlug($slug: String) {
    products(filters: {slug: {eq: $slug}}, publicationState: PREVIEW) {
        data {
            id
            attributes {
                publishedAt
                name
                slug
                wine {
                    information {
                        mainAppellation
                        wineAppellation
                        grapeVariety
                        alcohol
                        certification
                        bio
                    }
                    certification {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                    vinification {
                        method
                    }
                    packaging {
                        format
                        packaging
                        format_list_wine
                    }
                    medal {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                    terroir {
                        wineRegion
                        surface
                        soilType
                        climate
                    }
                    tasting {
                        eye
                        nose
                        mouth
                    }
                    wineService {
                        tastingTemperature
                        foodWinePairing
                        wineConservation
                    }
                }
              beer{
                  beerInformation{
                    alcohol
                    country
                    bio
                  }
                  beerPackaging{
                    id
                    volumeBouteille
                    volumeFut
                    formatFut
                    teteFut
                    consigneBouteille
                    conditionnementBouteille
                    format
                  }
                  beerCertification {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                  beerMedal {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                  beerTasting {
                        eye
                        nose
                        mouth
                    }
                  beerService {
                        tastingTemperature
                        foodBeerPairing
                        beerConservation
                    }
                }
                image {
                    data {
                        id
                        attributes {
                            url
                            alternativeText
                        }
                    }
                }
                company {
                    data {
                        id
                        attributes {
                            name
                            slug
                        }
                    }
                }
                productSubCategory {
                    data {
                        id
                        attributes {
                            name
                            product_categories {
                                data {
                                    id
                                    attributes {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
                product_sub_3_cat {
                    data {
                        id
                        attributes {
                            name
                        }
                    }
                }
                product_sub_4_cat {
                    data {
                        id
                        attributes {
                            name
                        }
                    }
                }
            }
        }
    }
}
`

export const isProductExist = gql`
    query isProductExist($name: String) {
        products(filters: { name: { eqi: $name } }, publicationState: PREVIEW) {
            data {
                id
            }
        }
    }
`


export const getProductByCompany = gql`
query getProductByCompany($id: ID!, $page: Int = 1, $max: Int = 12, $andFilters: [ProductFiltersInput]) {
    products(
        filters: {
            company: { id: { eq: $id } }
            and: $andFilters
        }
        publicationState: PREVIEW
        pagination: { pageSize: $max, page: $page }
        sort: "createdAt:desc"
    ) {
        meta {
            pagination {
                total
            }
        }
        data {
            id
            attributes {
                name
                slug
                wine {
                    information {
                        mainAppellation
                        wineAppellation
                        grapeVariety
                        alcohol
                        certification
                        bio
                    }
                    certification {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                    vinification {
                        method
                    }
                    packaging {
                        format
                        packaging
                    }
                    medal {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                    terroir {
                        wineRegion
                        surface
                        soilType
                        climate
                    }
                    tasting {
                        eye
                        nose
                        mouth
                    }
                    wineService {
                        tastingTemperature
                        foodWinePairing
                        wineConservation
                    }
                }
                  beer{
                  beerInformation{
                    alcohol
                    country
                    bio
                  }
                  beerPackaging{
                    id
                    volumeBouteille
                    volumeFut
                    formatFut
                    teteFut
                    consigneBouteille
                    conditionnementBouteille
                    format
                  }
                  beerCertification {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                  beerMedal {
                        image1 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image2 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image3 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image4 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                        image5 {
                            data {
                                id
                                attributes {
                                    url
                                    alternativeText
                                }
                            }
                        }
                    }
                  beerTasting {
                        eye
                        nose
                        mouth
                    }
                  beerService {
                        tastingTemperature
                        foodBeerPairing
                        beerConservation
                    }
                }
                image {
                    data {
                        id
                        attributes {
                            url
                            alternativeText
                        }
                    }
                }
                company {
                    data {
                        id
                        attributes {
                            name
                            slug
                        }
                    }
                }
                productSubCategory {
                    data {
                        id
                        attributes {
                            name
                            product_categories {
                                data {
                                    id
                                    attributes {
                                        name
                                    }
                                }
                            }
                        }
                    }
                }
                product_sub_3_cat {
                    data {
                        id
                        attributes {
                            name
                        }
                    }
                }
                product_sub_4_cat {
                    data {
                        id
                        attributes {
                            name
                        }
                    }
                }
            }
        }
    }
}
`

export const createBeerProduct = gql`
    mutation createProducts(
        $name: String
        $slug: String
        $alcohol: String
      	$country: ENUM_COMPONENTBEERINFORMATIONBEERINFORMATION_COUNTRY
      	$bio: ENUM_COMPONENTBEERINFORMATIONBEERINFORMATION_BIO
      	$format: [ComponentBeerPackagingBeerPackagingInput]
        $company: ID
        $productSubCategory: ID
        $product_sub_3_cat: ID
        $image: ID
        $cert1: ID
        $cert2: ID
        $cert3: ID
        $cert4: ID
        $cert5: ID
        $medal1: ID
        $medal2: ID
        $medal3: ID
        $medal4: ID
        $medal5: ID
      	$eye: String
        $nose: String
        $mouth: String
        $tastingTemperature: String
        $foodBeerPairing: String
        $beerConservation: String
    ) {
        createProduct(
            data: {
                name: $name
                slug: $slug
              	beer: {
                	beerInformation: {
                    alcohol : $alcohol
                    country: $country
                    bio: $bio
                  }
                  beerPackaging: $format
                  beerCertification: {
                        image1: $cert1
                        image2: $cert2
                        image3: $cert3
                        image4: $cert4
                        image5: $cert5
                  }
                  beerMedal : {
                        image1: $medal1
                        image2: $medal2
                        image3: $medal3
                        image4: $medal4
                        image5: $medal5
                  }
                  beerTasting: { 
                    	eye: $eye, 
                    	nose: $nose, 
                    	mouth: $mouth 
                  }
                  beerService: {
                      tastingTemperature: $tastingTemperature
                      foodBeerPairing: $foodBeerPairing
                      beerConservation: $beerConservation
                  }
                }
                company: $company
                productSubCategory: $productSubCategory
                product_sub_3_cat: $product_sub_3_cat
                image: $image
            }
        ) {
            data {
                id
                attributes {
                    slug
                    beer{
                        beerCertification {
                            id
                        }
                        beerMedal {
                            id
                        }
                    }
                }
            }
        }
    }
`


export const createProducts = gql`
    mutation createProducts(
        $name: String
        $slug: String
        $mainAppellation: String
        $wineAppellation: String
        $grapeVariety: String
        $alcohol: String
        $certification: String
        $bio: ENUM_COMPONENTINFORMATIONINFORMATIONS_BIO
        $format: String
        $packaging: String
        $wineRegion: String
        $surface: String
        $soilType: String
        $climate: String
        $method: String
        $eye: String
        $nose: String
        $mouth: String
        $tastingTemperature: String
        $foodWinePairing: String
        $wineConservation: String
        $company: ID
        $productSubCategory: ID
        $product_sub_3_cat: ID
        $product_sub_4_cat: ID
        $image: ID
        $cert1: ID
        $cert2: ID
        $cert3: ID
        $cert4: ID
        $cert5: ID
        $medal1: ID
        $medal2: ID
        $medal3: ID
        $medal4: ID
        $medal5: ID
        $format_list_wine: JSON
    ) {
        createProduct(
            data: {
                name: $name
                slug: $slug
                wine: {
                    information: {
                        mainAppellation: $mainAppellation
                        wineAppellation: $wineAppellation
                        grapeVariety: $grapeVariety
                        alcohol: $alcohol
                        certification: $certification
                        bio: $bio
                    }
                    certification: {
                        image1: $cert1
                        image2: $cert2
                        image3: $cert3
                        image4: $cert4
                        image5: $cert5
                    }
                    vinification: { method: $method }
                    packaging: { format: $format, packaging: $packaging, format_list_wine: $format_list_wine }
                    medal: {
                        image1: $medal1
                        image2: $medal2
                        image3: $medal3
                        image4: $medal4
                        image5: $medal5
                    }
                    terroir: {
                        wineRegion: $wineRegion
                        surface: $surface
                        soilType: $soilType
                        climate: $climate
                    }
                    tasting: { eye: $eye, nose: $nose, mouth: $mouth }
                    wineService: {
                        tastingTemperature: $tastingTemperature
                        foodWinePairing: $foodWinePairing
                        wineConservation: $wineConservation
                    }
                }
                company: $company
                productSubCategory: $productSubCategory
                product_sub_3_cat: $product_sub_3_cat
                product_sub_4_cat: $product_sub_4_cat
                image: $image
            }
        ) {
            data {
                id
                attributes {
                    slug
                    wine {
                        certification {
                            id
                        }
                        medal {
                            id
                        }
                    }
                }
            }
        }
    }
`

export const updateProducts = gql`
    mutation updateProducts(
        $id: ID!
        $name: String
        $slug: String
        $mainAppellation: String
        $wineAppellation: String
        $grapeVariety: String
        $alcohol: String
        $certification: String
        $bio: ENUM_COMPONENTINFORMATIONINFORMATIONS_BIO
        $format: String
        $packaging: String
        $wineRegion: String
        $surface: String
        $soilType: String
        $climate: String
        $method: String
        $eye: String
        $nose: String
        $mouth: String
        $tastingTemperature: String
        $foodWinePairing: String
        $wineConservation: String
        $company: ID
        $productSubCategory: ID
        $product_sub_3_cat: ID
        $product_sub_4_cat: ID
        $image: ID
        $cert1: ID
        $cert2: ID
        $cert3: ID
        $cert4: ID
        $cert5: ID
        $medal1: ID
        $medal2: ID
        $medal3: ID
        $medal4: ID
        $medal5: ID
        $format_list_wine: JSON
    ) {
        updateProduct(
            id: $id
            data: {
                name: $name
                slug: $slug
                wine: {
                    information: {
                        mainAppellation: $mainAppellation
                        wineAppellation: $wineAppellation
                        grapeVariety: $grapeVariety
                        alcohol: $alcohol
                        certification: $certification
                        bio: $bio
                    }
                    certification: {
                        image1: $cert1
                        image2: $cert2
                        image3: $cert3
                        image4: $cert4
                        image5: $cert5
                    }
                    vinification: { method: $method }
                    packaging: { format: $format, packaging: $packaging, format_list_wine: $format_list_wine }
                    medal: {
                        image1: $medal1
                        image2: $medal2
                        image3: $medal3
                        image4: $medal4
                        image5: $medal5
                    }
                    terroir: {
                        wineRegion: $wineRegion
                        surface: $surface
                        soilType: $soilType
                        climate: $climate
                    }
                    tasting: { eye: $eye, nose: $nose, mouth: $mouth }
                    wineService: {
                        tastingTemperature: $tastingTemperature
                        foodWinePairing: $foodWinePairing
                        wineConservation: $wineConservation
                    }
                }
                company: $company
                productSubCategory: $productSubCategory
                product_sub_3_cat: $product_sub_3_cat
                product_sub_4_cat: $product_sub_4_cat
                image: $image
            }
        ) {
            data {
                id
                attributes {
                    slug
                    wine {
                        certification {
                            id
                        }
                        medal {
                            id
                        }
                    }
                }
            }
        }
    }
`

export const updateBeerProduct = gql`
    mutation updateBeerProduct(
        $id: ID!
        $name: String
        $slug: String
        $alcohol: String
      	$country: ENUM_COMPONENTBEERINFORMATIONBEERINFORMATION_COUNTRY
      	$bio: ENUM_COMPONENTBEERINFORMATIONBEERINFORMATION_BIO
      	$format: [ComponentBeerPackagingBeerPackagingInput]
        $company: ID
        $productSubCategory: ID
        $product_sub_3_cat: ID
        $image: ID
        $cert1: ID
        $cert2: ID
        $cert3: ID
        $cert4: ID
        $cert5: ID
        $medal1: ID
        $medal2: ID
        $medal3: ID
        $medal4: ID
        $medal5: ID
      	$eye: String
        $nose: String
        $mouth: String
        $tastingTemperature: String
        $foodBeerPairing: String
        $beerConservation: String
    ) {
        updateProduct(
            id: $id
                data: {
                name: $name
                slug: $slug
              	beer: {
                	beerInformation: {
                    alcohol : $alcohol
                    country: $country
                    bio: $bio
                  }
                  beerPackaging: $format
                  beerCertification: {
                        image1: $cert1
                        image2: $cert2
                        image3: $cert3
                        image4: $cert4
                        image5: $cert5
                  }
                  beerMedal : {
                        image1: $medal1
                        image2: $medal2
                        image3: $medal3
                        image4: $medal4
                        image5: $medal5
                  }
                  beerTasting: { 
                    	eye: $eye, 
                    	nose: $nose, 
                    	mouth: $mouth 
                  }
                  beerService: {
                      tastingTemperature: $tastingTemperature
                      foodBeerPairing: $foodBeerPairing
                      beerConservation: $beerConservation
                  }
                }
                company: $company
                productSubCategory: $productSubCategory
                product_sub_3_cat: $product_sub_3_cat
                image: $image
            }
        
        ) {
            data {
                id
                attributes {
                    slug
                    beer {
                        beerCertification {
                            id
                        }
                        beerMedal {
                            id
                        }
                    }
                }
            }
        }
    }
`